<template>
  <div>
    <PageHeader title="Openstaande transacties (TEST)" />
    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <FormulateForm
        v-if="isStaff"
        #default="{ isLoading }"
        v-model="query"
        name="filterTransactions"
        @submit="filterTransactions"
        class="tw-mb-8 tw-p-6 tw-bg-white tw-rounded tw-shadow-lg"
      >
        <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
          <FormulateInput
            type="autocomplete"
            auto-complete-type="office"
            name="office"
            label="Kantoren"
            placeholder="Selecteer een kantoor"
            :disabled="query.collaborator.id"
            :title="query.collaborator.id ? textSelectOneFilter : ''"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="collaborator"
            :params="{ include_out_of_service: 1 }"
            name="collaborator"
            label="Medewerker"
            placeholder="Selecteer een medewerker"
            :disabled="query.office.id"
            :title="query.office.id ? textSelectOneFilter : ''"
            outer-class="tw-m-0"
          />
        </div>
        <FormulateErrors />
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :help="textSelectOneFilter"
          help-position="before"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-search'
            ]"
          />
          Zoeken
        </FormulateInput>
      </FormulateForm>
      <DataTable
        :loading="loading"
        :headers="headers"
        v-bind="transactionsData"
        :paginate="true"
        @changePage="fetchTransactions"
      >
        <template #item-reference="{ item }">
          <a :href="`/transaction/view/${item.id}`" :title="`View ${item.reference}`">
            {{ item.reference }}
          </a>
        </template>
        <template #item-is_approved="{ item }">
          <button
            type="button"
            title="Goedkeuringen voor deze transactie"
            :class="[
              'tw-px-1.5 tw-py-0.5 tw-rounded tw-text-white',
              item.is_approved ? 'tw-bg-primary' : 'tw-bg-danger'
            ]"
            @click="showTransactionApprovalModal(item.id)"
          >
            {{ item.approval_status }}
          </button>
        </template>
        <template #item-is_invoiced="{ item }">
          <span :class="['tw-px-1.5 tw-py-0.5 tw-rounded tw-text-white', item.is_invoiced ? 'tw-bg-primary' : 'tw-bg-danger']">
            {{ item.is_invoiced ? 'Ja' : 'Nee' }}
          </span>
        </template>
        <template #item-is_paid="{ item }">
          <span :class="['tw-px-1.5 tw-py-0.5 tw-rounded tw-text-white', item.is_paid ? 'tw-bg-primary' : 'tw-bg-danger']">
            {{ item.is_paid ? 'Ja' : 'Nee' }}
          </span>
        </template>
        <template #item-is_payment_blocked="{ item }">
          <span v-if="item.is_payment_blocked" class="tw-px-1.5 tw-py-0.5 tw-rounded">
            <i class="far fa-lock" />
          </span>
        </template>
        <template #item-actions="{ item }">
          <button
            v-if="isSuperUser"
            title="Transactie doorboeken"
            class="action tw-bg-primary"
            @click="bookTransaction(item.id)"
          >
            <i class="far fa-book"/>
          </button>
        </template>
      </DataTable>
    </div>
    <Modal ref="transactionApproval" title="Goedkeuringen voor deze transactie">
      <DataTable
        :loading="loadingTransactionApprovals"
        :headers="transactionApprovalHeaders"
        v-bind="transactionApprovalData"
        class="tw--m-5 tw-shadow-none"
        @changePage="fetchTransactionApprovals"
      >
        <template #item-approval="{ item }">
          <span
            :class="[
              'tw-px-1.5 tw-py-0.5 tw-rounded tw-text-white',
              item.approved_on ? 'tw-bg-primary' : 'tw-bg-danger'
            ]"
          >
            <template v-if="item.approved_on">{{ item.approved_on | date-day }}</template>
            <template v-else>Nee</template>
          </span>
        </template>

        <template #item-action="{ item }">
          <!-- Collaborators are only allowed to approve their own open approvals !-->
          <button
            v-if="!item.approved_on && item.collaborator.id === collaborator.id && !approving"
            @click="approveTransaction(item.transaction_id, item.id)"
            class="btn btn-xs btn-primary"
          >
            Nu goedkeuren
          </button>
          <template v-else-if="approving === item.id">
            <i class="fa fa-spinner fa-spin tw-mr-2" />
            <span>Goedkeuren ...</span>
          </template>
        </template>
      </DataTable>
    </Modal>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable'
import Modal from '@/components/iam/Modal'

import { mapState } from 'vuex'
import { getTransactions2, getTransactionApprovals } from '@/services/commission'
import { createTransactionBooking, updateTransactionApprovalDetails } from '@/services/transactions'
import { questionModal, startLoadingModal, successModal, errorModal } from '@/modalMessages'

export default {
  name: 'Transactions',
  components: {
    PageHeader,
    DataTable,
    Modal
  },
  data () {
    return {
      loading: false,
      loadingTransactionApprovals: false,
      query: {
        // Empty strings to not get the error of property being undefined when dealing with :disabled for the autocomplete inputs
        collaborator: '',
        office: ''
      },
      // The transactionsData includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      transactionsData: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      transactionApprovalData: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      approving: null
    }
  },
  computed: {
    ...mapState('account', ['user']),

    isStaff () {
      return this.user.is_staff
    },
    isSuperUser () {
      return this.user.is_superuser
    },
    collaborator () {
      return this.user.collaborator
    },
    headers () {
      return [
        { text: 'Referentie', value: 'reference' },
        { text: 'Adres', value: 'address' },
        { text: 'Goedgekeurd', value: 'is_approved' },
        { text: 'Gefactureerd', value: 'is_invoiced' },
        { text: 'Betaald', value: 'is_paid' },
        { text: 'Geblokkeerd', value: 'is_payment_blocked' },
        { text: 'Actie', value: 'actions' }
      ]
    },
    transactionApprovalHeaders () {
      return [
        { text: 'Medewerker', value: 'collaborator.display_name' },
        { text: 'Goedkeuring', value: 'approval' },
        { text: 'Actie', value: 'action' }
      ]
    },
    textSelectOneFilter () {
      return 'Je kan slechts één van beide filters invullen, kantoor of medewerker'
    }
  },
  created () {
    this.fetchTransactions()
  },
  methods: {
    showTransactionApprovalModal (transactionId) {
      this.transactionApprovalData = {
        count: null,
        next: null,
        previous: null,
        results: []
      }
      this.$refs.transactionApproval.show()
      this.fetchTransactionApprovals({ transactionId })
    },
    async fetchTransactionApprovals (payload) {
      try {
        this.loadingTransactionApprovals = true
        const response = await getTransactionApprovals(payload)
        this.transactionApprovalData = response.data
        return response
      } catch (error) {
        errorModal('Fout bij laden van transactiegoedkeuringen')
        throw error
      } finally {
        this.loadingTransactionApprovals = false
      }
    },
    async fetchTransactions (payload) {
      try {
        this.loading = true
        const response = await getTransactions2(payload)
        this.transactionsData = response.data
        return response
      } catch (error) {
        errorModal('Fout bij het laden van transacties')
        throw error
      } finally {
        this.loading = false
      }
    },
    async filterTransactions (data) {
      try {
        const params = { ...data }

        if (params.office) params.office_id = params.office.id
        delete params.office

        if (params.collaborator) params.collaborator_id = params.collaborator.id
        delete params.collaborator

        return await this.fetchTransactions({ params })
      } catch (error) {
        this.$formulate.handle(error, 'filterTransactions')
      }
    },
    async bookTransaction (transactionId) {
      try {
        const result = await questionModal('Ben je zeker dat je deze transactie wil doorboeken?')
        if (!result.value) return

        startLoadingModal('De transactie wordt doorgeboekt...')
        const response = await createTransactionBooking(transactionId)
        successModal('Transactie is doorgeboekt')

        // Remove booked transaction from the page
        const idx = this.transactionsData.results.findIndex((t) => t.id === transactionId)
        this.transactionsData.results.splice(idx, 1)

        return response
      } catch (error) {
        errorModal('Fout bij het boeken van de transactie, probeer het opnieuw.')
      }
    },
    async approveTransaction (transactionId, approvalId) {
      try {
        this.approving = approvalId
        const payload = {
          approved_on: new Date()
        }
        const response = await updateTransactionApprovalDetails(transactionId, approvalId, payload)
        await Promise.all([this.fetchTransactionApprovals({ transactionId }), this.fetchTransactions()])
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het goedkeuren van deze transactie.')
      } finally {
        this.approving = null
      }
    }

  }
}
</script>
