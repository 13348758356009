var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"title":"Openstaande transacties (TEST)"}}),_c('div',{staticClass:"tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto"},[(_vm.isStaff)?_c('FormulateForm',{staticClass:"tw-mb-8 tw-p-6 tw-bg-white tw-rounded tw-shadow-lg",attrs:{"name":"filterTransactions"},on:{"submit":_vm.filterTransactions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"office","name":"office","label":"Kantoren","placeholder":"Selecteer een kantoor","disabled":_vm.query.collaborator.id,"title":_vm.query.collaborator.id ? _vm.textSelectOneFilter : '',"outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"collaborator","params":{ include_out_of_service: 1 },"name":"collaborator","label":"Medewerker","placeholder":"Selecteer een medewerker","disabled":_vm.query.office.id,"title":_vm.query.office.id ? _vm.textSelectOneFilter : '',"outer-class":"tw-m-0"}})],1),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"help":_vm.textSelectOneFilter,"help-position":"before"}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-search'
          ]}),_vm._v(" Zoeken ")])]}}],null,false,1420844838),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}):_vm._e(),_c('DataTable',_vm._b({attrs:{"loading":_vm.loading,"headers":_vm.headers,"paginate":true},on:{"changePage":_vm.fetchTransactions},scopedSlots:_vm._u([{key:"item-reference",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":("/transaction/view/" + (item.id)),"title":("View " + (item.reference))}},[_vm._v(" "+_vm._s(item.reference)+" ")])]}},{key:"item-is_approved",fn:function(ref){
          var item = ref.item;
return [_c('button',{class:[
            'tw-px-1.5 tw-py-0.5 tw-rounded tw-text-white',
            item.is_approved ? 'tw-bg-primary' : 'tw-bg-danger'
          ],attrs:{"type":"button","title":"Goedkeuringen voor deze transactie"},on:{"click":function($event){return _vm.showTransactionApprovalModal(item.id)}}},[_vm._v(" "+_vm._s(item.approval_status)+" ")])]}},{key:"item-is_invoiced",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:['tw-px-1.5 tw-py-0.5 tw-rounded tw-text-white', item.is_invoiced ? 'tw-bg-primary' : 'tw-bg-danger']},[_vm._v(" "+_vm._s(item.is_invoiced ? 'Ja' : 'Nee')+" ")])]}},{key:"item-is_paid",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:['tw-px-1.5 tw-py-0.5 tw-rounded tw-text-white', item.is_paid ? 'tw-bg-primary' : 'tw-bg-danger']},[_vm._v(" "+_vm._s(item.is_paid ? 'Ja' : 'Nee')+" ")])]}},{key:"item-is_payment_blocked",fn:function(ref){
          var item = ref.item;
return [(item.is_payment_blocked)?_c('span',{staticClass:"tw-px-1.5 tw-py-0.5 tw-rounded"},[_c('i',{staticClass:"far fa-lock"})]):_vm._e()]}},{key:"item-actions",fn:function(ref){
          var item = ref.item;
return [(_vm.isSuperUser)?_c('button',{staticClass:"action tw-bg-primary",attrs:{"title":"Transactie doorboeken"},on:{"click":function($event){return _vm.bookTransaction(item.id)}}},[_c('i',{staticClass:"far fa-book"})]):_vm._e()]}}])},'DataTable',_vm.transactionsData,false))],1),_c('Modal',{ref:"transactionApproval",attrs:{"title":"Goedkeuringen voor deze transactie"}},[_c('DataTable',_vm._b({staticClass:"tw--m-5 tw-shadow-none",attrs:{"loading":_vm.loadingTransactionApprovals,"headers":_vm.transactionApprovalHeaders},on:{"changePage":_vm.fetchTransactionApprovals},scopedSlots:_vm._u([{key:"item-approval",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:[
            'tw-px-1.5 tw-py-0.5 tw-rounded tw-text-white',
            item.approved_on ? 'tw-bg-primary' : 'tw-bg-danger'
          ]},[(item.approved_on)?[_vm._v(_vm._s(_vm._f("date-day")(item.approved_on)))]:[_vm._v("Nee")]],2)]}},{key:"item-action",fn:function(ref){
          var item = ref.item;
return [(!item.approved_on && item.collaborator.id === _vm.collaborator.id && !_vm.approving)?_c('button',{staticClass:"btn btn-xs btn-primary",on:{"click":function($event){return _vm.approveTransaction(item.transaction_id, item.id)}}},[_vm._v(" Nu goedkeuren ")]):(_vm.approving === item.id)?[_c('i',{staticClass:"fa fa-spinner fa-spin tw-mr-2"}),_c('span',[_vm._v("Goedkeuren ...")])]:_vm._e()]}}])},'DataTable',_vm.transactionApprovalData,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }